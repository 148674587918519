import React, { useEffect, useState } from "react";
import instance from "../../../../../auth/utils/useJwt";
import SelectedPermissions from "./SelectedPermissions";
import PermissionsSelector from "./PermissionSelector";
import { Box, Button, Stack, Typography } from "@mui/material";
import useSnackbar from "../../../../../components/Dialogs/SnackBar";
import AdminAction from "../../../../../components/PermissionsWrappers/AdminAction";
export default function AdminUserPermissions({
    user_id
}) {
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const [selectedPermissions, setSelectedPermissions] = useState([]);

    const [SnackbarComponent, showSnackbar] = useSnackbar();
    const fetchPermissions = () => {
        setLoading(true);
        instance.get(`/admin/user/user-privileges?user_id=${user_id}`).then(res => {
            console.log(res.data)
            setSelectedPermissions(res.data);
            setLoading(false);
        }).catch(err => {
            setError("Failed to fetch permissions");
            setLoading(false);
        });
    };

    const updatePermissions = (permissions) => {
        setLoading(true);
        instance.post("/admin/user/user-privileges", {
            permissions: permissions,
            user_id: user_id
        }).then(res => {
            showSnackbar("Permissions updated successfully", "success");
            setLoading(false);
            fetchPermissions();
        }).catch(err => {
            showSnackbar("Failed to update permissions", "error");
            setLoading(false);
        });
    }


    useEffect(() => {
        fetchPermissions();
    }, []);





    return (
        <div>
            <SnackbarComponent />
            <Box>
                <Typography variant="h6">Assign Roles</Typography>
                <Typography variant="body2" sx={{
                    color: "#888", mt: 1, mb: 3
                }}>
                    Roles are composed of sets of permissions and determine what the principal can do with this resource.
                </Typography>
            </Box>
            {/* {loading && <p>Loading...</p>} */}
            {/* {error && <p>{error}</p>} */}

            <SelectedPermissions selectedPermissions={selectedPermissions} setSelectedPermissions={setSelectedPermissions} />
            
            <AdminAction>
            <PermissionsSelector selectedPermissions={selectedPermissions} setSelectedPermissions={setSelectedPermissions} />


            <Stack direction={"row"} sx={{ my: 5 }}>
                <Button variant="contained" size="small"
                    onClick={() => updatePermissions(selectedPermissions)}
                    disabled={loading}
                >Save</Button>
            </Stack>
            </AdminAction>
        </div>
    );
}

import React, { useState, useEffect } from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { useParams } from 'react-router-dom';
import Box from '@mui/material/Box';
import instance from '../../../../auth/utils/useJwt';

export default function Audit(props) {
    const [auditData, setAuditData] = useState([])
    const { case_id } = useParams()
    const [apiReq, setApiReq] = useState({ loading: true, error: null })
  

    const fetchCaseStatus = () => {
        setApiReq({ loading: true, error: null })
        instance.get(`/case-status?patient_id=${props.patient_id}&case_id=${case_id}`).then(res => {
            console.log(res)
            setApiReq({ loading: false, error: null })
            setAuditData(res.data)
        }).catch(err => {
            setApiReq({ loading: false, error: null })
            console.log(err)
        })
    }

    useEffect(() => {
        fetchCaseStatus()
    }, [])



    return (
        <Box>
            <TableContainer>
                <Table sx={{ minWidth: 650 }} aria-label="simple table" size="small">
                    <TableHead sx={{ backgroundColor: '#f6f6f7' }}>
                        <TableRow>
                            <TableCell>Date / Time</TableCell>
                            <TableCell>Who</TableCell>
                            <TableCell>Status</TableCell>
                            <TableCell>Action</TableCell>
                            <TableCell>Comment</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                    {
                            apiReq.loading &&
                            <TableRow>
                                <TableCell colSpan={5} sx={{ backgroundColor: "#F2F9FE", textAlign: 'center', py: 1, color: 'rgb(84, 176, 242)' }}>
                                    Loading...
                                </TableCell>
                            </TableRow>
                        }
                        {
                            auditData.length === 0  && !apiReq.loading &&
                            <TableRow>
                                <TableCell colSpan={5} sx={{ backgroundColor: "#F2F9FE", textAlign: 'center', py: 1, color: 'rgb(84, 176, 242)' }}>There are no memos.</TableCell>
                            </TableRow>
                        }
                        {auditData.map((row, index) => (
                            <TableRow
                                key={`r43r-${index}-${row.name}`}
                                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                            >
                                <TableCell sx={{ minWidth: '150px' }}>{row.timestamp}</TableCell>
                                <TableCell sx={{ py: 1.1 }}>{row.who}</TableCell>
                                <TableCell>{row.status}</TableCell>
                                <TableCell>{ row.action }</TableCell>
                                <TableCell>{row.comment}</TableCell>
                                
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </Box>
    );
}


import { useEffect, useState } from "react";
import { Button, Card, CardContent, CircularProgress, Grid } from "@mui/material";
import AppLayout from "../../../components/layout/AppLayout";
import Typography from '@mui/material/Typography'
import Box from '@mui/material/Box'
import dayjs from 'dayjs';
import FiltersForm from "./FiltersForm";
import instance from "../../../auth/utils/useJwt";
import BreadCrumbs from "../../../components/BreadCrumbs";
import { DATE_FORMAT } from "../../../configs/basic";
import BContainer from "../../../components/BContainer";
import DataGridTable from "./DataTable";

const YearList = () => {
    const startYear = 2004;
    const currentYear = new Date().getFullYear() + 1;
    const years = [];

    for (let i = startYear; i < currentYear; i++) {
        years.push({ label: `${i} / ${String(i + 1).slice(2)}`, value: `${i} / ${String(i + 1).slice(2)}` });
    }
    return years.reverse()
}

export default function InsuranceReports() {
    const [insuranceReportsData, setInsuranceReportsData] = useState(null)
    const [apiReq, setApiReq] = useState({
        loading: false, error: null
    })

    
    const [filters, setFilters] = useState({
        financial_year: YearList()[0],
        from: dayjs().subtract(12, 'month').format(DATE_FORMAT),
        to: dayjs().format(DATE_FORMAT), status: null, region: null, district: null,
    })

    const onClear = () => {
        setFilters({
            financial_year: YearList()[0],
            from: dayjs().subtract(8, 'month').format(DATE_FORMAT),
            to: dayjs().format(DATE_FORMAT), status: null, region: null, district: null,
        })
    }

    const fetchReports = () => {
        setApiReq({ loading: true, error: null })
        instance.post("/reports/insurance", filters).then(res => {
            // add index to id
            res.data.forEach((item, index) => {
                item.id = `${item.id}-${index}`
            })
            setInsuranceReportsData(res.data)
            setApiReq({ loading: false, error: null })
        }).catch(err => {

            setApiReq({ loading: false, error: "Error fetching records" })
        })
    }

 
    useEffect(() => {
        fetchReports()
    }, [])

    const breadcrumbs_v = [
        { url: "#", text: "Reports" },
        { text: "Insurance" },
    ]

    return (
        <AppLayout px={0} apppadding={"0px"}>
            <BContainer>
                <BreadCrumbs data={breadcrumbs_v} />
            </BContainer>
            <Box sx={{ px: 3, py: 1 }}>
                {/* <Card>
                    <Box sx={{
                        display: 'flex', justifyContent: 'space-between', py: 1.5, px: 1,
                        borderBottom: '1px solid #b8bfe3'
                    }}>
                        <Box sx={{ pt: 0.5, px: 1 }}>
                            <Typography variant="h6">Filters</Typography>
                        </Box>
                    </Box>
                    <Box sx={{ p: 2 }}>
                        <FiltersForm filters={filters} setFilters={setFilters} fetchData={fetchReports} onClear={onClear} loading={apiReq.loading} />
                    </Box>
                </Card> */}

                <Box sx={{ textAlign: 'center', py: 2 }}>

                </Box>


                <Card>
                    <DataGridTable data={insuranceReportsData} filters={filters} />
                </Card>





            </Box>

        </AppLayout>
    )
}
import React, { useEffect, useRef, useState } from 'react';
import { Box, Card, CardContent } from '@mui/material';
import Markdown from 'react-markdown';
import gfm from 'remark-gfm';
import './styles.css';

export default function SingleDocx({ md, toc, setToc, setSelectedToc }) {
    const contentRef = useRef(null);
    
    const [sections, setSections] = useState([]);
    // Function to generate a unique ID based on the heading text
    const generateId = (text) => {
        return text.toLowerCase().replace(/[^a-z0-9]+/g, '-');
    };

    useEffect(() => {
        if (md && contentRef.current) {
            console.log("Generating ToC")
            const headings = contentRef.current.querySelectorAll('h2, h3');

            const foundHeadings = Array.from(headings).map(heading => {
                const id = generateId(heading.textContent);
                heading.id = id; // Assign the generated ID to the heading element

                return {
                    level: heading.tagName === 'H2' ? 2 : 3,
                    text: heading.textContent,
                    id: id
                };
            });

            setToc && setToc(foundHeadings); // Update ToC in parent component if required
        }
    }, [md, setToc, sections]);


    useEffect(() => {
        if (md) {
            // Split the markdown into sections based on level 3 headings
            const splitRegex = /(?=^###\s.*$)/gm;
            const rawSections = md.split(splitRegex).filter(section => section.trim() !== '');
            setSections(rawSections);
        }
    }, [md]);

    return (
        <Box>
            <div className='custom-markdown' ref={contentRef}>
            {sections.map((section, index) => (
                    <Card key={`section-${index}`} className='custom-morkdown-section'>
                        <CardContent>
                        <Markdown remarkPlugins={[gfm]}>
                            {section}
                        </Markdown>
                        </CardContent>
                    </Card>
                ))}
            </div>
        </Box>
    );
}

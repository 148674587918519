import { Box, Card, Grid } from "@mui/material";
import BContainer from "../../../../components/BContainer";
import AppLayout from "../../../../components/layout/AppLayout";
import BreadCrumbs from "../../../../components/BreadCrumbs";
import { useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import instance from "../../../../auth/utils/useJwt";
import MetaCard from "./MetaCard";
import DetailsTabs from "./Tabs";
import UserDetailSkeleton from "./components/DetailsLoadingSkeleton";

export default function AdminUserDetails() {
    const { uuid } = useParams()
    const [loading, setLoading] = useState(true)
    const [userDetails, setUserDetails] = useState(null)
    const fetchUserDetails = () => {
        // fetch user details
        setLoading(true)
        instance.get(`/admin/users?uuid=${uuid}`).then(response => {
            setUserDetails(response.data)
            setLoading(false)
        }).catch(error => {
            console.log(error)
            setLoading(false)
        }
        )
    }

    useEffect(() => {
        fetchUserDetails()
    }, [uuid])

    return (
        <AppLayout title="Admin - Users" px={0} apppadding={"0px"}>
            <BContainer>
                <Box sx={{ display: 'flex', justifyContent: 'center', flexDirection: 'column' }}>
                    <BreadCrumbs data={[
                        { url: "#", text: "Admin" },
                        { url: "/admin/users", text: "Users" },
                        { text: "User Info" }
                    ]} />
                </Box>

            </BContainer>
            <Box sx={{ px: 2, py: 1 }}>
                {
                    loading && <UserDetailSkeleton />
                }
                {userDetails &&
                    <Grid container spacing={2}>
                        <Grid item md={4} lg={3}>
                            <MetaCard data={userDetails} />
                        </Grid>
                        <Grid item md={8} lg={9}>
                            <DetailsTabs data={userDetails} />
                        </Grid>
                    </Grid>

                }
            </Box>
        </AppLayout>
    )

}
import { useEffect, useState } from "react";
import { Button, Card, CardContent, CircularProgress, Grid, Stack } from "@mui/material";
import Typography from '@mui/material/Typography'
import Box from '@mui/material/Box'
import instance from "../../../auth/utils/useJwt";
import GeneralDialog from "../../../components/Dialogs/CustomDialog";



export default function ExportInsuranceReports({
    filters
}) {
    const [isExporting, setIsExporting] = useState(false)
    const [open, setOpen] = useState(false)
    const [apiResponse, setApiResponse] = useState(null)
    const [apiError, setApiError] = useState(null)


    const exportReports = () => {
        setIsExporting(true)
        setApiError(null)
        setApiResponse(null)
        const filtersCopy = { ...filters, export: true }
        instance.post("/reports/insurance", filtersCopy).then(res => {
            // add index to id
            setApiResponse(res.data)
            setIsExporting(false)
            // Also open a new tab to view the google sheet
            window.open(res.data, "_blank")
        }).catch(err => {
            setIsExporting(false)
            setApiError("Error exporting records")
        })
    }

    const handleClose = () => {
        if (isExporting) return
        setOpen(false)
    }


    return (
        <>
            <Box sx={{ pt: 1, pr: 1 }}>
                <Button size="small" variant="contained" color="primary" onClick={
                    () => setOpen(true)
                }>Export</Button>
            </Box>
            <GeneralDialog open={open} handleClose={handleClose} title="Export Insurance Reports" actions={
                <Stack direction="row" spacing={2}>
                    <Button variant="outlined" size="small" color="primary" disabled={isExporting} onClick={handleClose}>Cancel</Button>
                    <Button variant="contained" size="small" color="primary" disabled={isExporting}
                        onClick={exportReports}>
                            {isExporting ? "Exporting..." : "Export"}
                        </Button>
                </Stack>
            } disabled={isExporting}>
                <Box>
                    <Typography variant="body1" gutterBottom>
                        Exporting Insurance Reports will take some time. Please be patient.
                    </Typography>
                    {
                        apiResponse && (
                            <Typography variant="body1" gutterBottom color="success">
                                <a style={{
                                    color: "green", textDecoration: "underline"
                                }} href={apiResponse.url} target="_blank" rel="noreferrer">View in Google Sheets</a>
                            </Typography>
                        )
                    }
                    {
                        apiError && (
                            <Typography variant="body1" gutterBottom color="error">
                                {apiError}
                            </Typography>
                        )
                    }
                </Box>
            </GeneralDialog>
        </>
    )
}
import { Avatar, Box, Card, Stack, Typography } from "@mui/material";
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import DeleteUser from "./components/DeleteUser";
import SuspendUser from "./components/SuspendUser";
import XeroAccess from "./components/XeroAccess";
import ResetUserPassword from "./components/ResetPassword";
import AdminAction from "../../../../components/PermissionsWrappers/AdminAction";
export default function MetaCard({ data }) {
    console.log(data)
    return (
        <Card>

            <Stack spacing={2} direction="row" sx={{ px: 2, py: 2, borderBottom: '1px solid #ddd' }}>
                <Box sx={{ mt: 1 }}>
                    <Avatar src={data.first_name} />
                </Box>
                <Box sx={{ mt: 2 }}>
                    <Typography variant="h6">{data.name} {data.last_name}</Typography>
                    <Typography>{data.email}</Typography>

                    <Stack sx={{ mt: 2 }} direction={"column"}>
                        <Typography variant="caption" color={data.enabled ? "success" : "error"} sx={{
                            color: data.enabled ? "#4CAF50" : "#F44336"
                        }}>
                        { data.enabled ? "Active" : "Inactive" }
                        </Typography>
                        <Typography variant="caption" color="text.secondary">
                            Last sign in: {data.last_login}
                        </Typography>
                        <Typography variant="caption" color="text.secondary">
                            Created: {data.created}
                        </Typography>
                    </Stack>
                </Box>
            </Stack>
            <AdminAction>
            <List>
                {
                    [
                        { title: "Reset Password", comp: <ResetUserPassword id={data.id} />},
                        // { title: "Update User", comp: null},
                        // {title: "Add to Groups", comp: null}, 
                        {title: "Suspend User", comp: <SuspendUser id={data.id} enabled={data.enabled} />},
                        // { title: "XERO Access", comp: <XeroAccess id={data.id} access={data.xero_access} />},
                        {title: "Delete User", comp: <DeleteUser id={data.id} />}
                    ].map((item, index) => (
                        <ListItem key={index} sx={{ my: 0, py: 0 }}>
                            { item.comp ? item.comp :
                            <ListItemButton sx={{ py: 1, my: 0 }}>
                            <Typography>{item.title}</Typography>
                            </ListItemButton>
                            }
                        </ListItem>

                    ))
                }
            </List>
            </AdminAction>
        </Card>
    )
}
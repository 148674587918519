import * as React from 'react';
import { DataGrid, GridToolbar } from '@mui/x-data-grid';
import { styled } from '@mui/material';
import LinearProgress from '@mui/material/LinearProgress';
import ModeEditOutlineOutlinedIcon from '@mui/icons-material/ModeEditOutlineOutlined';
import DeleteForeverOutlinedIcon from '@mui/icons-material/DeleteForeverOutlined';
import IconButton from '@mui/material/IconButton';



const StyledDataGrid = styled(DataGrid)({
    '& .MuiDataGrid-cell': {
        borderRight: '1px solid #ddd', // Add vertical borders to cells
    },
    '& .MuiDataGrid-columnHeader': {
        backgroundColor: '#eee', // Set table heading row background to #eee
    },
});


export default function DataGridTable(props) {
    const { loading, data, cols } = props;
    // ... rest of your component
    const columns = [
        { field: 'id', headerName: 'ID', width: 50, align: 'center', headerAlign: 'center' },
        ...cols,
        {
            field: 'actions',
            headerName: 'Actions',
            sortable: false,
            width: 100,
            //disableClickEventBubbling: true,
            renderCell: (params) => {
                return (
                    <div>
                        <IconButton aria-label='Edit' onClick={() => params.row.id && props.handleEdit(params.row)}>
                            <ModeEditOutlineOutlinedIcon sx={{ fontSize: '20px' }} />
                        </IconButton>

                        <IconButton aria-label='Delete' onClick={() => params.row.id && props.handleDelete(params.row.id)}>
                            <DeleteForeverOutlinedIcon sx={{ fontSize: '20px' }} />
                        </IconButton>
                    </div>
                );
            }
        }
    ];
    return (
        <div style={{ maxHeight: 600, width: '100%' }}>
            <StyledDataGrid
                rows={data}
                columns={columns}
                autoHeight
                loading={loading}
                initialState={{
                    pagination: {
                        paginationModel: { page: 0, pageSize: 10 },
                    },
                }}
                disableRowSelectionOnClick ={true}
                sx={{ border: 'none' }}
                pageSizeOptions={[10, 25]}
                slots={{ toolbar: GridToolbar, loadingOverlay: LinearProgress, }}
                density="compact"

            />
        </div>
    );
}


import * as React from 'react';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Tooltip from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';
import Badge from '@mui/material/Badge';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import NotificationsOutlinedIcon from '@mui/icons-material/NotificationsOutlined';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import instance from '../../auth/utils/useJwt';
import { Card, CircularProgress, LinearProgress } from '@mui/material';
import { Link } from 'react-router-dom';
import AppLayout from '../../components/layout/AppLayout';

export default function NotificationsPage() {
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    const [processing, setProcessing] = React.useState(false)
    const [loading, setLoading] = React.useState(false)

    const [notifications, setNotifications] = React.useState([])
    const [unreadNotifications, setUnreadNotifications] = React.useState(0)
    const get_notifications = () => {
        setLoading(true)
        instance.get("/notifications?type=all").then(res => {
            setNotifications(res.data?.notifications || [])
            setUnreadNotifications(res.data?.unread || 0)
            setLoading(false)
        }).catch(err => {
            console.log(err)
            setLoading(false)
        })
    }

    const setAllRead = () => {
        setProcessing(true)
        instance.patch("/notifications", {
            read: true
        },
            {
                headers: {
                    'Content-Type': 'application/json'
                }
            }).then(res => {
                setProcessing(false)
                get_notifications()
            }).catch(err => {
                setProcessing(false)
            })
    }

    React.useEffect(() => {
        get_notifications()
    }, [])



    return (
        <AppLayout px={0} apppadding={"0px"}>
        <Box sx={{ display: 'flex', justifyContent: 'space-between', borderBottom: '1px solid #ccc', py: 1.7, px: 3, backgroundColor: "#fff", mb: 2, borderRadius: 'none' }}>
            <Typography variant="h6">All Notifications ({unreadNotifications})</Typography>
            
            <Tooltip title="Mark all as read">
                        {processing ? <CircularProgress size={20} /> :
                            <IconButton size="small" onClick={setAllRead} disabled={processing || notifications.filter(item => item.read === false).length === 0}>
                                <CheckCircleOutlineIcon color="primary" />
                            </IconButton>
                        }
                    </Tooltip>
        </Box>

            <Card sx={{ maxWidth: '800px',
                    margin: '0 auto',

             }}>
                { loading && <LinearProgress /> }
                <Box sx={{ maxHeight: "calc(100vh - 200px)",
                    overflow: "auto" }}>
                {notifications.map((item, index) => (
                    <MenuItem key={`not-${index}`} sx={{ display: 'flex', my: 1, justifyContent: 'space-between',
                        backgroundColor: item.read ? '#fff' : '#f9f9f9'
                    }} onClick={handleClose}>
                        <Box sx={{ display: 'flex', gap: '7px' }}>
                            {/* <Avatar sx={{ width: '38px', height: '38px' }} /> */}
                            <Box>
                                <Typography sx={{ textTransform: 'capitalize', fontSize: '13px', fontWeight: 550 }}>
                                    {item.type?.replace("_", " ")?.toLowerCase()}
                                    {item.creator ? ` by "${item.creator}" ` : ' '} <span style={{ color: '#4B465C', textTransform: 'lowercase' }}>
                                    [{item.timeago}]
                                    </span>
                                </Typography>
                                <Typography sx={{ fontSize: '11px', color: '#4B465C' }}>{
                                    item.message
                                }</Typography>
                            </Box>
                        </Box>
                        {/* <Box>
                            <Typography sx={{ fontSize: '11px' }}>
                                {item.timeago}
                            </Typography>
                        </Box> */}
                    </MenuItem>
                ))}
                </Box>

            </Card>
            </AppLayout>
    );
}
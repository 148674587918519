import { Box, Card, CardContent, CardHeader, Table, TableBody, TableCell, TableRow, Typography } from "@mui/material";

export default function UserDetails({data}) {
    const { phone, country, region, timezone, xero_access, xero_pam, donations, role } = data;
    return (
        <Box>
        <Card>
            <CardHeader title="User Details" sx={{ fontSize: '17px' }} />
            <CardContent>
                <Box>
                    <Table>
                        <TableBody>
                            <TableRow>
                                <TableCell>Phone</TableCell>
                                <TableCell>{phone ? phone : 'N/A'}</TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell>Country</TableCell>
                                <TableCell>{country ? country : 'N/A'}</TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell>Region</TableCell>
                                <TableCell>{region ? region : 'N/A'}</TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell>Timezone</TableCell>
                                <TableCell>{timezone ? timezone : 'N/A'}</TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell>Xero Access</TableCell>
                                <TableCell>{xero_access ? 'Yes' : 'No'}</TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell>Xero PAM</TableCell>
                                <TableCell>{xero_pam ? 'Yes' : 'No'}</TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell>Donations</TableCell>
                                <TableCell>{donations ? 'Yes' : 'No'}</TableCell>
                            </TableRow>
                        </TableBody>
                    </Table>
                </Box>
            </CardContent>
        </Card>

            {/* Permissions  "type" : roles.type,
                "description" : roles.description,
                "permissions" : roles.permissions,
                "name" : roles.name, 
                "district" : roles.district, "region" : roles.region */}

        <Card sx={{ mt: 2 }}>
            <CardHeader title="Permissions" sx={{ fontSize: '17px' }} />
            <CardContent>
                { !role ? <Box>
                    <Typography>No Permissions Assigned</Typography>
                </Box> :
                <Box>
                    <Table>
                        <TableBody>
                            <TableRow>
                                <TableCell>Name</TableCell>
                                <TableCell>{role.name}</TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell>Type</TableCell>
                                <TableCell>{role.type}</TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell>Description</TableCell>
                                <TableCell>{role.description}</TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell>Permissions</TableCell>
                                <TableCell>{role.permissions}</TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell>District</TableCell>
                                <TableCell>{role.district}</TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell>Region</TableCell>
                                <TableCell>{role.region}</TableCell>
                            </TableRow>
                        </TableBody>
                    </Table>
                </Box>
                }
            </CardContent>
        </Card>

        </Box>
    )
}
import React, { useState, useEffect, useMemo } from "react";
import instance from "../../../auth/utils/useJwt";
import AppLayout from "../../../components/layout/AppLayout";
import BContainer from "../../../components/BContainer";
import BreadCrumbs from "../../../components/BreadCrumbs";
import { Box, Tab } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { styled } from '@mui/material/styles';
import './styles.css'
import ExportInvoices from "./components/ExportInvoices";
import InvoicesGrid from "./InvoicesGrid";


const StyledTab = styled(Tab)(({ theme }) => ({
    textTransform: 'none',
    borderRightWidth: 1,
    borderTopWidth: 1,
    borderStyle: 'solid',
    borderColor: theme.palette.divider,
}));





export default function PAMInvoices() {
    const [invoices, setInvoices] = useState([]);
    const [loading, setLoading] = useState(true);
    const [selectedTab, setSelectedTab] = useState("All");
    const [countEachStatus, setCountEachStatus] = useState({});

    const navigate = useNavigate();

    const fetchInvoices = () => {
        setLoading(true)
        instance.get("/xero/invoices").then(res => {
            setInvoices(res.data)
            setLoading(false)
        }).catch(err => {
            console.log(err)
            setLoading(false)
        })
    }
    const filteredInvoices = invoices.filter((invoice) => {
        // If the selected tab is "All", return all invoices except the deleted ones
        if (selectedTab === "All")  return invoice.Status !== "DELETED";
        if (selectedTab === "Draft" && invoice.Status === "DRAFT") return true;
        if (selectedTab === "Awaiting Payment" && invoice.Status === "AUTHORISED") return true;
        if (selectedTab === "Paid" && invoice.Status === "PAID") return true;
        if (selectedTab === "Deleted" && invoice.Status === "DELETED") return true;
        return false;
    });

    useEffect(() => {
        const count = {}
        invoices.forEach((invoice) => {
            if (count[invoice.Status]) {
                count[invoice.Status] += 1
            } else {
                count[invoice.Status] = 1
            }
        })
        setCountEachStatus(count)
    }, [invoices])

    const handleTabChange = (event, newValue) => {
        setSelectedTab(newValue);
    };

    useEffect(() => {
        if (invoices.length === 0)
        fetchInvoices()
    }, [invoices.length])


    const tabOptions = useMemo(() => [
        { label: "All", value: "All", id: "all", count: invoices.filter((invoice) => invoice.Status !== "DELETED").length },
        { label: "Draft", value: "Draft", id: "draft", count: countEachStatus["DRAFT"] },
        { label: "Awaiting Payment", value: "Awaiting Payment", id:"awaiting-payment", count: countEachStatus["AUTHORISED"] },
        { label: "Paid", value: "Paid", id: "paid", count: countEachStatus["PAID"] },
        { label: "Deleted", value: "Deleted", id: "deleted", count: countEachStatus["DELETED"] }
    ], [invoices, countEachStatus])

    
    const tab = new URLSearchParams(window.location.search).get("tab");

    useEffect(() => {
        // Filter the invoices based on the selected tab and update the url based on id
        const tabOption = tabOptions.find((option) => option.value === selectedTab);
        if (tabOption && tabOption.id !== tab) {
            navigate(`?tab=${tabOption.id}`, { replace: true });
        }
    }, [selectedTab, navigate, tabOptions, tab]);

    useEffect(() => {
        // Search for tab option against id and set tab value
        const tabOption = tabOptions.find((option) => option.id === tab);
        if (!tabOption) {
            setSelectedTab("All");
            // Also update the url
            if (tab !== 'all') {
                navigate(`?tab=all`, { replace: true });
            }
        } else {
            setSelectedTab(tabOption.value);
        }
    }, [tab, tabOptions, navigate, setSelectedTab]);

    

    return (
        <AppLayout title="Expenses" px={0} apppadding={"0px"}>
            <style>
                {`
                button[aria-label="Export"] {
                    display: inline-flex;
                }
                `}
            </style>                
                
            <BContainer>
                <BreadCrumbs data={[
                    { text: "Expenses" }
                ]} />
                <Box sx={{ pt:0.4 }}>
                <ExportInvoices
                startDate={invoices.length > 0 ? invoices[invoices.length - 1].Date : null}
                endDate={invoices.length > 0 ? invoices[0].Date : null}
                options={tabOptions.map((option) => option.label)}
                />
                </Box>
            </BContainer>
            <Box>
                <InvoicesGrid invoices={filteredInvoices} loading={loading} 
                selectedTab={selectedTab} handleTabChange={handleTabChange} tabOptions={tabOptions} />
            </Box>
        </AppLayout>
    )
}

import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import instance from '../../../../auth/utils/useJwt';

import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button'
import { useNavigate, useParams } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { useForm } from "react-hook-form";
import { Box, DialogContentText, IconButton } from '@mui/material';
import Close from '@mui/icons-material/Close';



export default function DeleteInvoice(props) {
    const { invoice } = props
    const { invoice_id } = useParams()
    const [open, setOpen] = useState(false)
    const [apiReq, setApiReq] = useState({
        loading: false, error: null
    })
    const navigate = useNavigate()
    const { register, handleSubmit,
        formState: { errors },
    } = useForm();

    const onSubmit = () => {
        setApiReq({ loading: true, error: null })
        instance.delete(`/case/xero-invoices?invoice_id=${invoice_id}`).then(res => {
            setOpen(false)
            setApiReq({ loading: false, error: null })
            props.onInvoiceDeleted()
        }).catch(err => {
            console.log("Error")
            //props.setOpen(false)
            //props.showSnackbar("Error deleting case", "error")

            setApiReq({ loading: false, error: "Error deleting invoice" })
        })
    }

    const onError = (error) => {
        //alert("error")
    }

    const handleClose = () => {
        setOpen(false)
    }
    console.log(invoice)

    return (
        <>
        { invoice_id !== "new" && invoice !== null &&
            <Button size='small' onClick={() => setOpen(true)} variant="outlined" color="error" sx={{
                    textTransform: 'none',
                }}
                disabled={apiReq.loading || props.disabled || ['PAID', 'DELETED'].includes(invoice?.Status)}>
                    Delete
            </Button>
        }
   
        <Dialog
            open={open}
            fullWidth={true}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >

            <form onSubmit={handleSubmit(onSubmit, onError)}>
                <DialogTitle id="alert-dialog-title" sx={{
                    borderBottom: '1px solid #ddd', mb: 1, display: "flex", justifyContent: "space-between"
                }}>
                    {"Delete Invoice"}

                    <Box>
                        <IconButton size="small" onClick={handleClose} disabled={apiReq.loading}>
                            <Close />
                        </IconButton>
                    </Box>
                </DialogTitle>
                <DialogContent>
                    <DialogContentText sx={{ my: 1 }}>Are you sure you want to delete this invoice. Please enter Invoice id to delete this invoice.
                        "<span style={{ color: "#09f" }}>{invoice_id}</span>"
                    </DialogContentText>
                    <TextField size="small" {...register('invoice_id', {
                        required: true, validate: (value) => {
                            // Check if the value matches the expected value
                            console.log(value, invoice_id)
                            return value === invoice_id || 'Invalid invoice ID';
                        }
                    })} name="invoice_id"
                    placeholder='Enter Invoice ID'
                    fullWidth
                        error={Boolean(errors.invoice_id)}
                    />



                </DialogContent>
                <DialogActions sx={{ py: 2, pr: 3, borderTop: '1px solid #ddd' }}>
                    <Button onClick={handleClose} disabled={apiReq.loading} variant="outlined">Cancel</Button>
                    <Button type="submit" disabled={apiReq.loading} variant="contained" color="error">
                        {
                            apiReq.loading ? `DELETING` : `DELETE`
                        }
                    </Button>
                </DialogActions>

            </form>
        </Dialog>
        </>
    )
}
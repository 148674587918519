import { Box, Card, CardContent, Typography } from "@mui/material";

export default function Security() {
    return (
        <Card>
            <Box sx={{
                backgroundColor: "#FAFAFA",
                px: 3, py: 2, borderBottom: '1px solid #E0E0E0'
            }}>
                Security
            </Box>
            <CardContent>
                <Typography>Security settings</Typography>
            </CardContent>
        </Card>
    )
}
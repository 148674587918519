import React, { useEffect, useState } from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import instance from "../../../../auth/utils/useJwt";
import { Box, Grid, IconButton } from "@mui/material";
import CustomInputLabel from "../../../../components/forms/CustomInputLabel";
import DatePicker from "../../../../components/forms/DatePicker";
import CloseIcon from '@mui/icons-material/Close';
import MUIAutocompleteMultipleCheck from "../../../../components/forms/MUIAutocompleteMultipleCheck";
import { useForm } from "react-hook-form";
import ExitToAppOutlinedIcon from '@mui/icons-material/ExitToAppOutlined';
import GeneralDialog from "../../../../components/Dialogs/CustomDialog";
export default function ExportInvoices({
    startDate, endDate, options, variant="contained"
}) {
    const [exporting, setExporting] = useState(false);
    const [open, setOpen] = useState(false);
    const [response, setResponse] = useState(null);
    const defaultOptionState = [
        { label: "All", value: "All" }
    ]
    const { control, handleSubmit, getValues, reset,
        formState: { errors },
    } = useForm({
        initialValues: {
            options: defaultOptionState
        }
    });
    const handleClickOpen = () => {
        setOpen(true);
    };

    const [exportData, setExportData] = useState({
        start_date: null, end_date: null, options: ["All"]
    });

    const handleClose = () => {
        reset({ options: defaultOptionState })
        setOpen(false);
    };
    const setDate = (dt) => {
        setExportData({ ...exportData, [dt.name]: dt.value });
    }

    useEffect(() => {
        setExportData({ ...exportData, start_date: startDate, end_date: endDate });
    }, [startDate, endDate]);

    const onSubmit = data => {
        const { options } = getValues();
        data.options = options.map(option => option.value);

        // if any option is "AWAITING PAYMENT" then add "AUTHORISED" to options
        if (data.options.includes("Awaiting Payment")) {
            data.options.push("AUTHORISED");
            // remove "Awaiting Payment" from options
            data.options = data.options.filter(option => option !== "Awaiting Payment");
        }

        setExporting(true);
        instance.post("/xero/invoices/export", data)
            .then(res => {
                window.open(res.data, '_blank', 'noreferrer');
                setExporting(false);
                handleClose();
            }).catch(err => {
                console.log(err);
                setExporting(false);
                handleClose();
            });
    };

    return (
        <>
        { variant === "default" ?
            <Button size="small"
                startIcon={<ExitToAppOutlinedIcon />}
                onClick={handleClickOpen} disabled={exporting}>
                {exporting ? "Exporting..." : "Export"}
            </Button>
            :
            <Button size="small"
                startIcon={<ExitToAppOutlinedIcon />}
                onClick={handleClickOpen} disabled={exporting} variant="contained">
                {exporting ? "Exporting..." : "Export"}
            </Button>
}

            <GeneralDialog title={"Export Invoices"} open={open} handleClose={handleClose} disabled={exporting}
                actions={<Box sx={{ display: 'flex', gap: 2 }}>
                    <Button onClick={handleClose} color="primary" disabled={exporting} variant="outlined">
                        Cancel
                    </Button>
                    <Button type="submit" color="primary" disabled={exporting} variant="contained" onClick={() => onSubmit(exportData)}>
                        {exporting ? "Exporting..." : "Export"}
                    </Button>
                </Box>}>

                <Box>
                    <Grid container spacing={2}>
                        <Grid item xs={12} md={6}>
                            <Box>
                                <CustomInputLabel htmlFor="start_date">Start Date</CustomInputLabel>
                                <DatePicker name="start_date" onChange={setDate} value={exportData.start_date} />
                            </Box>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <Box>
                                <CustomInputLabel htmlFor="end_date">End Date</CustomInputLabel>
                                <DatePicker name="end_date" onChange={setDate} value={exportData.end_date} />
                            </Box>
                        </Grid>

                        <Grid item xs={12} md={12}>
                            <CustomInputLabel htmlFor="options">Status Options</CustomInputLabel>
                            <MUIAutocompleteMultipleCheck control={control} options={
                                options.map((option) => ({
                                    label: option,
                                    value: option
                                }))
                            }
                                defaultValue={defaultOptionState}
                                errors={errors} name="options" />
                        </Grid>
                    </Grid>
                </Box>

            </GeneralDialog>
        </>
    );
}

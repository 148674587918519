import { Box, styled, ToggleButton } from "@mui/material";
import { useState } from "react";
import CheckIcon from '@mui/icons-material/Check';
import CustomInputLabel from "../../../components/forms/CustomInputLabel";
import { useDispatch, useSelector } from "react-redux";
import { setAllCasesData } from "../../../redux/all_cases";
const SwitchButton = styled(Box)(({ theme }) => ({
  width: '50px',
  height: '100%',
  cursor: 'pointer',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  borderRadius: '5px',
  "&:hover": {
    backgroundColor: "#f0f0f0",
  },
}));

export default function StaredFilterSwitch() {
  const all_cases = useSelector(state => state.all_cases)
  const { queryArg } = all_cases
  const starred = queryArg === "starred";
  const dispatch = useDispatch()
  const height = 39;


  const setStarred = (value) => {
    dispatch(setAllCasesData({ key: 'queryArg', value: value ? "starred" : "active" }))
  }

  const handleYesClick = () => {
    setStarred(true);
  };



  const handleNoClick = () => {
    setStarred(false);
  };

  return (
    <Box>
      <CustomInputLabel sx={{ fontWeight: 700 }}>
        {'Starred'}
      </CustomInputLabel>
      <ToggleButton
      value="check"
      size="small"
      selected={starred}
      onChange={() => {
        setStarred(!starred);
      }}
    >
      <CheckIcon />
    </ToggleButton>
      {/* <Box sx={{
        border: '1px solid #e0e0e0',
        borderRadius: '5px',
        display: 'flex',
        height: `${height}px`,
      }}>
        <SwitchButton
          sx={{
            width: '50px',
            borderRight: '1px solid #e0e0e0',
            backgroundColor: starred ? '#09f' : '#fff',
            color: starred ? '#fff' : '#09f'
          }}
          onClick={handleYesClick}
        >
          Yes
        </SwitchButton>
        <SwitchButton
          sx={{
            width: '50px',
            height: '100%',
            backgroundColor: starred ? '#fff' : '#09f',
            color: starred ? '#09f' : '#fff'
          }}
          onClick={handleNoClick}
        >
          No
        </SwitchButton>
      </Box> */}
    </Box>
  );
}

import * as React from 'react';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import MuiDrawer from '@mui/material/Drawer';
import MuiAppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';

import AppLogo from '../../assets/images/icons/imgLogo.png'
import SidebarMenu from './sidebar/Menu';
import Profile from './comps/Profile';
import { useSelector } from 'react-redux';
import DocsSidebarMenu from './sidebar/DocsMenu';
import { useNavigate } from 'react-router-dom';
import HelpDocs from './comps/HelpDocs';
import ROMACApps from './comps/RomacApps';
import Notifications from './comps/Notifications';
const drawerWidth = 290;

const openedMixin = (theme) => ({
    width: drawerWidth,
    transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
    }),
    overflowX: 'hidden',
});

const closedMixin = (theme) => ({
    transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
    width: `0px`,
    [theme.breakpoints.up('sm')]: {
        width: `0px`,
    },
});

const DrawerHeader = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
}));

const AppBar = styled(MuiAppBar, {
    shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    margin: `0px`, width: '100%', borderRadius: '10px',
    padding: '0px 10px', backgroundColor: "#fff", boxShadow: '0px 2px 4px 0px rgba(165, 163, 174, 0.30)',
    ...(open && {
        marginLeft: 0,
        width: `100%`,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    }),
}));

const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
    ({ theme, open }) => ({
        width: drawerWidth,
        flexShrink: 0,
        whiteSpace: 'nowrap',
        boxSizing: 'border-box',
        ...(open && {
            ...openedMixin(theme),
            '& .MuiDrawer-paper': openedMixin(theme),
        }),
        ...(!open && {
            ...closedMixin(theme),
            '& .MuiDrawer-paper': closedMixin(theme),
        }),
    }),
);

export default function AppLayout({ children, apppadding }) {
    const [open, setOpen] = React.useState(true);
    const user = useSelector(state => state.user)
    const navigate = useNavigate()
    // If route starts with /docx, then set open to false
    // React.useEffect(() => {
    //     if (window.location.pathname.startsWith('/docx')) {
    //         setOpen(false);
    //     }
    // }, []);

    return (
        <Box sx={{ display: 'flex', backgroundColor: "#F8F7FA", minHeight: '100vh' }}>
            <AppBar open={open} position="fixed" sx={{ boxShadow: 'none', borderBottom: '1px solid #ccc', borderRadius: '0px' }}>
                <Toolbar disableGutters sx={{ display: 'flex', justifyContent: 'space-between', padding: 0, minHeight: '50px', borderRadius: '0px', px: 1.7 }}>
                    <Box sx={{ display: 'flex', minWidth: '300px', gap: '10px' }}>
                        <IconButton onClick={() => setOpen(!open)}>
                            <MenuIcon />
                        </IconButton>
                        <Box sx={{ display: 'flex', minWidth: '300px', cursor: 'pointer' }}
                        onClick={() => navigate("/")}
                        >
                            <img src={AppLogo} alt="ROMAC Icon" />
                            <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
                                <Typography variant='h5' sx={{
                                    color: '#222', mx: 1,
                                    fontWeight: 550
                                }}>ROMAC eHR</Typography>
                            </Box>
                        </Box>
                    </Box>
                    {/* <SearchBar /> */}
                    <Box sx={{ flexGrow: 0, gap: 1, display: 'flex' }}>
                        {/* { user.role?.type === "A" &&
                        <Settings />} */}
                        <HelpDocs />
                        <ROMACApps />
                        <Notifications />
                        <Profile />
                    </Box>
                </Toolbar>
            </AppBar>
            <Drawer variant="permanent" open={open}>
                <DrawerHeader sx={{
                    justifyContent: 'space-between', paddingLeft: '20px',
                    paddingRight: '20px'
                }}>
                    <Box sx={{ display: 'flex' }}>
                        <img src={AppLogo} alt="logo" />
                        <Typography variant='h5' sx={{
                            color: '#222', mx: 1, opacity: open ? 1 : 0,
                            fontWeight: 550
                        }}>ROMAC eHR</Typography>
                    </Box>
                </DrawerHeader>

                {window.location.pathname.startsWith('/docx') ?
                    <DocsSidebarMenu setOpen={setOpen} />
                    :
                    <SidebarMenu />
                }

            </Drawer>
            <Box component="main" className='main-content' sx={{ width: open ? `calc(100% - ${drawerWidth}px)` : '100%' }}>
                <Box sx={{ px: apppadding ? apppadding : 3 }}>
                    <DrawerHeader />
                    {children}
                </Box>
            </Box>
        </Box>
    );
}
